import { useEffect, useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Wallet from './../components/Wallet';
require('dotenv').config();

const Header = (props) => {

    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [balance, setBalance] = useState("");
    const [slpBalance, setSlpBalance] = useState("");
    const [skmBalance, setSkmBalance] = useState("");

    const tokenAddress = process.env.REACT_APP_SKUMY_TOKEN_ADDR;
    const tokenSymbol = 'SKM';
    const tokenDecimals = 18;
    const tokenImage = 'https://www.crocki.fr/_SKM/skm-token.jpg?v2';
    const adminAddress = process.env.REACT_APP_ADMIN_WALLET;

    useEffect(async () => {
        props.walletCallback(walletAddress);
        props.statusCallback(status);
        props.balanceCallback(balance);
        props.slpBalanceCallback(slpBalance);
        props.skmBalanceCallback(skmBalance);
    }, [walletAddress, status, balance, slpBalance, skmBalance]);

    async function setMMToken() {
        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: tokenAddress, // The address that the token is at.
                    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: tokenDecimals, // The number of decimals in the token
                    image: tokenImage, // A string url of the token logo
                },
                },
            });
            
            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Your loss!');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand className="logo" href="/"><img src="/assets/images/skm-token.jpg" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Buy</Nav.Link>
                        <Nav.Link hidden={(walletAddress != '0x5d0d93D9dc8C3fb5A76E3aF79e246104dA8080a7' && walletAddress != '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266' && walletAddress != '0x05DE7A226a70be4aa5300627184b59935eDbbC8c' && walletAddress != '0x1520c27375AafB457Bb461E8e3d3a8b3043CB9e7' && walletAddress != '0x96bf8F781CB863aAA91198bB18D8014804A5CD2E')} href="/trackmania">Trackmania</Nav.Link>
                        <Nav.Link href="/map-game">Map game</Nav.Link>
                        <Nav.Link href="/lp-staking">LP staking</Nav.Link>
                    </Nav>
                    <Nav className="admin-nav">
                        <Nav.Link hidden={(adminAddress != walletAddress)} href="/mint">Admin</Nav.Link>
                    </Nav>

                    <div className="balance-skm">{Math.round(skmBalance)} SKM</div>
                    <div className="add-to-mm"><span onClick={setMMToken}></span></div>
                    <Wallet statusCallback={setStatus} balanceCallback={setBalance} slpBalanceCallback={setSlpBalance} skmBalanceCallback={setSkmBalance} walletCallback={setWallet} />
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
    );
};

export default Header;
