import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import Header from './../components/Header';
import L from 'leaflet';
import icons from "leaflet-color-number-markers";
import moment from 'moment';
import {
  getEthBalance,
  buySkm,
  txnCheck,
  txnCheck2,
  approveSkmMapGame,
  getAllowanceMapGame,
  getShippingConfig,
  payShippingFees,
  distributeRewardsMapGame
} from "./../util/interact.js";

const MapGame = (props) => {

  /*
   * State variables
   */

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [balance, setBalance] = useState("");
  const [slpBalance, setSlpBalance] = useState("");
  const [skmBalance, setSkmBalance] = useState("");
  const [ethAmount, setEthAmount] = useState("");
  const [skmAmount, setSkmAmount] = useState("");
  const [noLiquidity, setnoLiquidity] = useState(false);
  const [pending, setPending] = useState(false);
  const [cardinalPoint, setCardinalPoint] = useState('');
  const [oldCardinalPoint, setOldCardinalPoint] = useState('');
  const [timeRemaining, setTimeRemaining] = useState('');
  const [roundNumber, setRoundNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [skmApproved, setSkmApproved] = useState(false);
  const [displayRules, setDisplayRules] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  /*
   * Conf variables
   */

  const REACT_APP_NODE_URL = process.env.REACT_APP_NODE_URL;
  let map;
  let gameConfig;
  let currentPosition = [];
  let pointPosition = [];
  let userPositions = {};
  let newWaitingPosition = false;
  const testMode = true; //FOR TEST ONLY
  const quickMode = false; //FOR TEST ONLY 

  //Shipping values are integrated in the smart contract - default values here
  let shipping = {
    plane: {
      speed: 3000,
      costPerKm: 0.1
    },
    car: {
      speed: 300,
      costPerKm: 0.01
    },
    bike: {
      speed: 50,
      costPerKm: 0
    }
  };

  const iconImg = new L.Icon({
    iconUrl: '/assets/images/marker-icon.png',
    iconRetinaUrl: '/assets/images/marker-icon-2x.png',
    iconAnchor: [13, 41],
    popupAnchor: [0, -41],
    shadowUrl: '/assets/images/marker-shadow.png',
    shadowSize: [41, 41],
    shadowAnchor: [13, 41],
    iconSize: [25, 41],
  });

  const iconBall = new L.Icon({
    iconUrl: '/assets/images/ball.png',
    iconAnchor: [15, 15],
    popupAnchor: [0, -15],
    iconSize: [30, 30],
  });


  /*
   * Component launch
   */

  useEffect(async () => {
    if(!walletAddress){
      return;
    }

    //Display rules
    if(!localStorage.getItem('rules')){
      setDisplayRules(true);
      localStorage.setItem('rules', '1');
    }

    //Get game config
    gameConfig = await getGameConfig();
    console.log(gameConfig);
    setRoundNumber(gameConfig.round);

    //Get shipping config from contract
    shipping = await getShippingConfig();
    console.log(shipping);

    //For quick mode
    if(testMode){
      shipping.plane.speed = 99999999999999;
      shipping.car.speed = 99999999999999;
      shipping.bike.speed = 99999999999999;
    } else if(quickMode){
      shipping.plane.speed = 3000000;
      shipping.car.speed = 300000;
      shipping.bike.speed = 50000;
    }

    //Get last played position in order to know if we must show new session modal or not
    const lastPlayedPosition = await getLastPlayedPosition();
    console.log('lastPlayedPosition');
    console.log(lastPlayedPosition);
    if(lastPlayedPosition && lastPlayedPosition.round != gameConfig.round){
      setModalTitle('Une nouvelle session a commencée !');
      setModalBody('Un joueur a trouvé le ballon de la session précédente, une nouvelle session vient donc de commencer !');
      handleShowModal();
    }

    //Check SKM approve
    const allowanceAmount = await getAllowanceMapGame(walletAddress);
    //console.log(allowanceAmount);
    if(allowanceAmount > 0){
        setSkmApproved(true);
    } else {
      console.log('modal allow');
      setModalTitle('Approuver l\'utilisation de vos SKM');
      setModalBody('Pour jouer à ce jeu, vous allez devoir utiliser des SKM pour payer les frais de transport. Veuillez donc approuver l\'utilisation de vos SKM :');
      handleShowModal();
    }

    //Get user position points
    userPositions = await getUserPositions();
    console.log(userPositions);

    //Actual position
    if(userPositions.actualPosition.lat){
      currentPosition = [userPositions.actualPosition.lat, userPositions.actualPosition.lng];
    } else {
      currentPosition = [gameConfig.start_lat, gameConfig.start_lng];
    }

    //Future position (if any)
    if(userPositions.futurePosition.lat){
      console.log(moment(userPositions.futurePosition.arrival_time).unix());
      console.log(moment().unix());
      if(moment(userPositions.futurePosition.arrival_time).unix() > moment().unix()){
        newWaitingPosition = true;
      } else {
        newWaitingPosition = false;
      }
    }

    pointPosition = [gameConfig.point_lat, gameConfig.point_lng];

    //Calculate heading and cardinal point
    const heading = angleFromCoordinate(currentPosition[0], currentPosition[1], gameConfig.point_lat, gameConfig.point_lng);
    console.log(heading);
    const cardinalPoint = cardinalPointFromHeading(heading);
    console.log(cardinalPoint);
    setCardinalPoint(cardinalPoint);

    //Init the map
    initMap();
  }, [walletAddress]);


  /*
   * Fetch balance
   */

  async function fetchBalance(address) {
    const balance = await getEthBalance(address);
    setBalance(balance);
  }


  /*
   * Game ajax functions
   */

  async function getGameConfig(){
    const res = await axios.get(REACT_APP_NODE_URL+'/get-game-config');
    return res.data[0];
  }

  async function setNewMovePoint(lat, lng, moveTime, cardinalPoint, marker){
    const post = {
      userID: walletAddress,
      lat: lat,
      lng: lng,
      moveTime: moveTime,
      cardinalPoint: cardinalPoint,
      round: gameConfig.round
    };

    const res = await axios.post(REACT_APP_NODE_URL+'/set-move-point', post);

    const ts = Math.floor(Date.now()/1000);
    const tsArrival = Math.floor(ts+(moveTime*3600))*1000;

    marker._popup.setContent('Votre destination en attente.<br>Arrivée prévue le '+moment(tsArrival).format('DD/MM/YYYY HH:mm:ss'));

    newWaitingPosition = true;
    setCardinalPoint('');

    calcTimeRemaining(moment(tsArrival/1000));

    marker.on('click', function(){
      setCardinalPoint('');
      setOldCardinalPoint('');
    });

    return res.data[0];
  }

  async function getUserPositions(){
    const res = await axios.get(REACT_APP_NODE_URL+'/get-user-positions?user_id='+walletAddress+'&round='+gameConfig.round);
    return res.data;
  }
  
  async function getLastPlayedPosition(){
    const res = await axios.get(REACT_APP_NODE_URL+'/get-last-played-position?user_id='+walletAddress);
    return res.data;
  }

  async function setNewGameRound(){
    const post = {
      round: gameConfig.round,
      winner: walletAddress
    };

    const res = await axios.post(REACT_APP_NODE_URL+'/set-new-game-round', post);
    return res.data;
  }


  /*
   * Game functions
   */

  function initMap(){
    //Reinit map for debug purpose
    document.querySelector('#map').remove();
    const mapDom = document.createElement('div');
    mapDom.setAttribute("id", "map");
    const mapWrapperDom = document.querySelector('#map-wrapper');
    mapWrapperDom.appendChild(mapDom);

    const mapInit = L.map('map').setView([51.505, -0.09], 13);
    map = mapInit;

    L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
      minZoom: 3,
      maxZoom: 20,
      zoom: 13,
      center: [51.505, -0.09],
      scrollWheelZoom: true,
      subdomains: ['mt0', 'mt1','mt2','mt3'],
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      noWrap: true,
    }).addTo(map);
    map.setMaxBounds(new L.LatLngBounds([-90,-180],   [90,180]));

    //Init ball marker
    const ballMarker = new L.marker(pointPosition, {icon: iconBall});

    //Display it only on max zoom lvl
    map.on('zoomend' , function (e) {
      console.log(map.getZoom());
      if (map.getZoom() >= 18){
        ballMarker.addTo(map);

        //Click on win button when ball is found
        ballMarker.addEventListener('click', function(event) {
          const indexPosition = userPositions.actualPosition.position_order;
          //TODO : get trueRewards from contract
          let trueRewards = Math.round(10000*(0.7 ** indexPosition));
          if(trueRewards < 100){
            trueRewards = 100;
          }
          ballMarker.bindPopup('<div class="content-rewards">Vous avez trouvé en '+indexPosition+' points ! Vous avez gagné <strong>'+trueRewards+' SKM</strong> !</div><button class="btn btn-info btn-win">Recevoir vos rewards</button>').openPopup();

          const btnWin = document.querySelectorAll('.btn-win');
          btnWin[0].addEventListener('click', function(event) {
            onGameWin();
          });
        });
      } else {
        ballMarker.remove();
      }
    });

    //Check index position for first move
    let indexPosition = 0;
    if(userPositions.actualPosition.lat){
      indexPosition = userPositions.actualPosition.position_order;
    }

    //Init last position point
    const lastPositionPoint = new L.marker(currentPosition, {icon: icons.orange.numbers[indexPosition]}).addTo(map).bindPopup("Vous êtes ici");
    lastPositionPoint.on('click', function(){
      setOldCardinalPoint('');
      const heading = angleFromCoordinate(currentPosition[0], currentPosition[1], gameConfig.point_lat, gameConfig.point_lng);
      const cardinalPoint = cardinalPointFromHeading(heading);
      setCardinalPoint(cardinalPoint);
    });

    //Archives positions - first is always starter round point
    if(currentPosition[0] != gameConfig.start_lat){
      const starterPositionPoint = new L.marker([gameConfig.start_lat, gameConfig.start_lng], {icon: icons.white.numbers[0]}).addTo(map).bindPopup("Point de départ");

      starterPositionPoint.on('click', function(){
        const firstHeading = angleFromCoordinate(gameConfig.start_lat, gameConfig.start_lng, gameConfig.point_lat, gameConfig.point_lng);
        const firstCardinalPoint = cardinalPointFromHeading(firstHeading);
        setOldCardinalPoint(firstCardinalPoint);
      });
    }

    userPositions.archivesPositions.forEach(position => {
      let archiveMarker = new L.marker([position.lat, position.lng], {icon: icons.blue.numbers[position.position_order]}).addTo(map).bindPopup("Arrivé ici le "+moment(position.arrival_time).format('DD/MM/YYYY HH:mm:ss'));

      archiveMarker.on('click', function(){
        setOldCardinalPoint(position.direction);
      });
    });

    //Future position
    if(userPositions.futurePosition.lat){
      const futurePositionPoint = new L.marker([userPositions.futurePosition.lat, userPositions.futurePosition.lng], {icon: iconImg}).addTo(map).bindPopup('Votre destination en attente.<br>Arrivée prévue le '+moment(userPositions.futurePosition.arrival_time).format('DD/MM/YYYY HH:mm:ss')).openPopup();

      map.panTo(new L.LatLng(userPositions.futurePosition.lat, userPositions.futurePosition.lng));

      calcTimeRemaining(moment(userPositions.futurePosition.arrival_time).unix());

      setCardinalPoint('');
      futurePositionPoint.on('click', function(){
        setCardinalPoint('');
        setOldCardinalPoint('');
      });
    } else {
      lastPositionPoint.openPopup();
      map.panTo(new L.LatLng(currentPosition[0], currentPosition[1]));
    }

    attachMapListenners();
  }

  async function onGameWin(){

    setPending(true);
    const indexPosition = userPositions.actualPosition.position_order;

    //If data is OK, Distribute rewards to winner
    //TODO - verify that user has really clicked on ball previously 
    const res = await distributeRewardsMapGame(walletAddress, indexPosition);

    if(res.isOK){
      txnCheck2(res.txnHash).then(async (receipt) => {
        //Reset game
        await setNewGameRound();

        setPending(false);

        //If all is OK, reload UI and let's go for next session!
        window.location.reload();
      });
    } else {
        setPending(false);
    }
  }


  /*
   * Game listenners
   */

  function attachMapListenners() {
    let oldMarker;

    //ON MAP CLICK - add a marker with popup that indicates costs and time to travel to this point
    map.on('click', function(e){
      //If user is already moving, do nothing
      if(newWaitingPosition){
        return;
      }

      console.log(currentPosition);
      if(oldMarker !== undefined) {
        map.removeLayer(oldMarker);
      }
      const distance = calculateDistance(currentPosition[0], currentPosition[1], e.latlng.lat, e.latlng.lng); 
      const distanceReadable = metersToKmDisplay(distance);

      const timePlane = (distance/1000)/shipping.plane.speed;
      const timeCar = (distance/1000)/shipping.car.speed;
      const timeBike = (distance/1000)/shipping.bike.speed;
      const costPlane = Math.round(shipping.plane.costPerKm*(distance/1000)*1000)/1000;
      const costCar = Math.round(shipping.car.costPerKm*(distance/1000)*1000)/1000;
      const costBike = Math.round(shipping.bike.costPerKm*(distance/1000)*1000)/1000;

      //Popup of clicked marker
      const htmlPopup = '\
      <div class="distance">\
        <strong>Distance: </strong>'+distanceReadable+'\
      </div>\
      <div class="ships">\
        <div class="ship car">\
        <button class="btn btn-info btn-goto" data-time="'+timePlane+'" data-cost="'+costPlane+'"><span class="icon-airplanemode_on"></span> Avion</button>\
          <div class="time"><span class="icon-access_time"></span> '+hrsToMinDisplay(timePlane)+'</div>\
          <div class="cost"><span class="icon-coin-dollar"></span> '+costPlane+' SKM</div>\
        </div>\
        <div class="ship plane">\
          <button class="btn btn-info btn-goto" data-time="'+timeCar+'" data-cost="'+costCar+'"><span class="icon-directions_car"></span> Voiture</button>\
          <div class="time"><span class="icon-access_time"></span> '+hrsToMinDisplay(timeCar)+'</div>\
          <div class="cost"><span class="icon-coin-dollar"></span> '+costCar+' SKM</div>\
        </div>\
        <div class="ship bike">\
          <button class="btn btn-info btn-goto" data-time="'+timeBike+'" data-cost="'+costBike+'"><span class="icon-directions_bike"></span> Vélo</button>\
          <div class="time"><span class="icon-access_time"></span> '+hrsToMinDisplay(timeBike)+'</div>\
          <div class="cost"><span class="icon-coin-dollar"></span> '+costBike+' SKM</div>\
        </div>\
      </div>\
      ';

      const marker = new L.marker(e.latlng, {icon: iconImg}).addTo(map).bindPopup(htmlPopup).openPopup();
      oldMarker = marker;

      //Buttons for move
      const btnsGoto = document.querySelectorAll('.btn-goto');
      btnsGoto.forEach(btn => {
        btn.addEventListener('click', function(event) {
          const moveTime = btn.getAttribute('data-time');
          const moveCost = btn.getAttribute('data-cost');

          console.log(moveCost);

          if(!testMode && moveCost > 0){
            setPending(true);

            //Pay shipping fees
            payShippingFees(moveCost).then((result) => {
              if(result.isOK){
                txnCheck2(result.txnHash).then((receipt) => {
                  //Calculate heading and cardinal point for future position
                  const heading = angleFromCoordinate(e.latlng.lat, e.latlng.lng, gameConfig.point_lat, gameConfig.point_lng);
                  const cardinalPoint = cardinalPointFromHeading(heading);
      
                  setNewMovePoint(e.latlng.lat, e.latlng.lng, moveTime, cardinalPoint, marker);

                  setPending(false);
                });
              } 
              //If tx is canceled or errored, reactivate btns
              else{
                setPending(false);
                setModalTitle('Fonds insuffisants');
                setModalBody("Vous n'avaez pas assez de SKM pour lancer ce trajet.");
                setShowModal(true);
              }
            });
          } else {
            //Calculate heading and cardinal point for future position
            const heading = angleFromCoordinate(e.latlng.lat, e.latlng.lng, gameConfig.point_lat, gameConfig.point_lng);
            const cardinalPoint = cardinalPointFromHeading(heading);

            setNewMovePoint(e.latlng.lat, e.latlng.lng, moveTime, cardinalPoint, marker);
          }
        });
      });
    });
  }


  /*
   * Utils functions
   */

  function calculateDistance(lat1, lon1, lat2, lon2) {
		var R = 6371000; // meters
		var dLat = toRad((lat2-lat1));
		var dLon = toRad((lon2-lon1));
		var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
		       Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
		       Math.sin(dLon/2) * Math.sin(dLon/2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		var d = R * c;
		return d;
	}

	function metersToKmDisplay(meters){
		if(meters >= 1000){
			var km = meters/1000;
			km = Math.round(km*10)/10
			return km+'km';
		} else {
			meters = Math.round(meters);
			return meters+'m';
		}
	}

  function toRad(deg) {
    return deg * Math.PI / 180;
  }

  function toDeg(rad) {
    return rad * 180 / Math.PI;
  }

  function hrsToMinDisplay(decimalTimeString){
    let decimalTime = parseFloat(decimalTimeString);
    decimalTime = decimalTime * 60 * 60;
    let hours = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    let minutes = Math.floor((decimalTime / 60));
    decimalTime = decimalTime - (minutes * 60);
    let seconds = Math.round(decimalTime);
    if(hours < 10)
    {
      hours = "0" + hours;
    }
    if(minutes < 10)
    {
      minutes = "0" + minutes;
    }
    if(seconds < 10)
    {
      seconds = "0" + seconds;
    }

    return hours+'h '+minutes+'min '+seconds+'s'
	}

  function angleFromCoordinate(lat1,lng1,lat2,lng2) {
    var dLon = toRad(lng2-lng1);
    var y = Math.sin(dLon) * Math.cos(toRad(lat2));
    var x = Math.cos(toRad(lat1))*Math.sin(toRad(lat2)) - Math.sin(toRad(lat1))*Math.cos(toRad(lat2))*Math.cos(dLon);
    var brng = toDeg(Math.atan2(y, x));
    return ((brng + 360) % 360);
  }

  function cardinalPointFromHeading(hdg){
    if((hdg > 337.5 && hdg <= 360)){
      return 'north';
    }
    else if(hdg >= 0 && hdg <= 22.5){
      return 'north';
    }
    else if(hdg > 22.5 && hdg <= 67.5){
      return 'north-east';
    }
    else if(hdg > 67.5 && hdg <= 112.5){
      return 'east';
    }
    else if(hdg > 112.5 && hdg <= 157.5){
      return 'south-east';
    }
    else if(hdg > 157.5 && hdg <= 202.5){
      return 'south';
    }
    else if(hdg > 202.5 && hdg <= 247.5){
      return 'south-west';
    }
    else if(hdg > 247.5 && hdg <= 292.5){
      return 'west';
    }
    else if(hdg > 292.5 && hdg <= 337.5){
      return 'north-west';
    }
  }

  function calcTimeRemaining(time){
    const myInterval = setInterval(() => {
      let now = moment().unix();
      let timeRemaining = time-now;
      setTimeRemaining(hrsToMinDisplay(timeRemaining/3600));

      if(timeRemaining <= 0){
        clearInterval(myInterval);
        window.location.reload();
        return;
      }
    }, 1000);
  }

  async function onApprove(){
    const res = await approveSkmMapGame();

    if(res.isOK){
        setPending(true);
        txnCheck2(res.txnHash).then((receipt) => {                
          setSkmApproved(true);
          setStatus(res.status);
          
          setPending(false);
          handleCloseModal();
        });
    } else {
        setStatus(res.status);
    }
  }

  function toggleRules(){
    setDisplayRules(!displayRules);
  }


  /*
   * Component rendering
   */

  return (
    <>
      <Header statusCallback={setStatus} balanceCallback={setBalance} slpBalanceCallback={setSlpBalance} skmBalanceCallback={setSkmBalance} walletCallback={setWallet} />

      <div className="view-map">
    
        <div id="map-wrapper">
          <div id="map"></div>
          <div className="loader-screen" hidden={!pending}>
            <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
          </div>
        </div>

        <div className="wrapper-heading">
          <div className="heading">
            <div className={`triangle north ${cardinalPoint === 'north' ? "active" : ""} ${oldCardinalPoint === 'north' ? "active-old" : ""}`}></div>
            <div className={`triangle north-east ${cardinalPoint === 'north-east' ? "active" : ""} ${oldCardinalPoint === 'north-east' ? "active-old" : ""}`}></div>
            <div className={`triangle east ${cardinalPoint === 'east' ? "active" : ""} ${oldCardinalPoint === 'east' ? "active-old" : ""}`}></div>
            <div className={`triangle south-east ${cardinalPoint === 'south-east' ? "active" : ""} ${oldCardinalPoint === 'south-east' ? "active-old" : ""}`}></div>
            <div className={`triangle south ${cardinalPoint === 'south' ? "active" : ""} ${oldCardinalPoint === 'south' ? "active-old" : ""}`}></div>
            <div className={`triangle south-west ${cardinalPoint === 'south-west' ? "active" : ""} ${oldCardinalPoint === 'south-west' ? "active-old" : ""}`}></div>
            <div className={`triangle west ${cardinalPoint === 'west' ? "active" : ""} ${oldCardinalPoint === 'west' ? "active-old" : ""}`}></div>
            <div className={`triangle north-west ${cardinalPoint === 'north-west' ? "active" : ""} ${oldCardinalPoint === 'north-west' ? "active-old" : ""}`}></div>
          </div>
        </div>

        <div className="eta ui-item" hidden={!timeRemaining}>
          <span className="icon-access_time"></span> <strong>{timeRemaining}</strong>
        </div>

        <div className="infos ui-item">
          <div className="session-number">Session <strong>n°{roundNumber}</strong></div>
          <div className="player-number"></div>
        </div>

        <button onClick={toggleRules} className="btn btn-info btn-rules">Voir les règles</button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{__html: modalBody}}></Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleCloseModal} hidden={!skmApproved}>
            Fermer
          </Button>
          <Button variant="info" onClick={onApprove} hidden={skmApproved} disabled={pending}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
            Approuver SKM
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="rules" hidden={!displayRules}>
        <img src="/assets/images/rules.jpg" />
        <div className="close-rules" onClick={toggleRules}>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default MapGame;
