require('dotenv').config();

const ethers = require('ethers');
const contractSkmABI = require("../contracts-abi/Skumy.sol/Skumy.json");
const contractSkmAddress = process.env.REACT_APP_SKUMY_TOKEN_ADDR;
const contractPSaleABI = require("../contracts-abi/PSale.sol/PSale.json");
const contractPSaleAddress = process.env.REACT_APP_PSALE_CONTRACT_ADDR;
const contractTrackmaniaABI = require("../contracts-abi/Trackmania.sol/Trackmania.json");
const contractTrackmaniaAddress = process.env.REACT_APP_TRACKMANIA_CONTRACT_ADDR;
const contractSlpABI = require("../contracts-abi/SLPTest.sol/SLPTest.json");
const contractSlpAddress = process.env.REACT_APP_SLP_CONTRACT_ADDR;
const contractLpStakingABI = require("../contracts-abi/LPStaking.sol/LPStaking.json");
const contractLpStakingAddress = process.env.REACT_APP_LPSTAKING_CONTRACT_ADDR;
const contractMapGameABI = require("../contracts-abi/MapGame.sol/MapGame.json");
const contractMapGameAddress = process.env.REACT_APP_MAPGAME_CONTRACT_ADDR;
const explorerUrl = process.env.REACT_APP_EXPLORER_URL;

export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const signerAddr = await signer.getAddress();

        const obj = {
          status: "",
          address: signerAddr,
        };
        
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    }
};

export const getCurrentWalletConnected = async () => {
    console.log(explorerUrl);
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        if (signer) {
          const signerAddr = await signer.getAddress();

          return {
            address: signerAddr,
            status: "",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    }
};

export const getEthBalance = async (address) => {
  if (window.ethereum) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const balance = await provider.getBalance(address);
      const readableBalance = ethers.utils.formatEther(balance);
      return readableBalance;
    } catch (error) {
      console.log(error);
    }
  }
};

export const getSlpBalance = async (address) => {
  if (window.ethereum) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const slpContract = new ethers.Contract(contractSlpAddress, contractSlpABI, provider);
      const slpBalance = await slpContract.balanceOf(address);
      const readableSlpBalance = ethers.utils.formatEther(slpBalance);
      return readableSlpBalance;
    } catch (error) {
      console.log(error);
    }
  }
};

export const getSkmBalance = async (address) => {
  if (window.ethereum) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const skmContract = new ethers.Contract(contractSkmAddress, contractSkmABI, provider);
      const skmBalance = await skmContract.balanceOf(address);
      const readableSkmBalance = ethers.utils.formatEther(skmBalance);
      return readableSkmBalance;
    } catch (error) {
      console.log(error);
    }
  }
};

export const getSlpStakedAmount = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const addresses = await provider.listAccounts();
  const address = addresses[0];

  if (window.ethereum) {
    try {
      const LPStakingContract = new ethers.Contract(contractLpStakingAddress, contractLpStakingABI, provider);
      const slpStakedAmount = await LPStakingContract.getStakedAmount(address);
      const readableSlp = ethers.utils.formatEther(slpStakedAmount);
      return readableSlp;
    } catch (error) {
      console.log(error);
    }
  }
};

export const getSlpTotalSupply = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  if (window.ethereum) {
    try {
      const LPStakingContract = new ethers.Contract(contractLpStakingAddress, contractLpStakingABI, provider);
      const slpTotalSupply = await LPStakingContract.getSLPTotalSupply();
      const readableSlp = ethers.utils.formatEther(slpTotalSupply);
      return readableSlp;
    } catch (error) {
      console.log(error);
    }
  }
};

export const buySkm = async (address, amount) => {

  if (!window.ethereum || address === null) {
    return {
      status:
        "💡 Connect your Metamask wallet to update the message on the blockchain.",
    };
  }

  if (amount <= 0) {
    return {
      status: "❌ Please specify a valid amount",
    };
  }

  //set up transaction parameters
  const ethToPay = amount*0.025; //1 SKM = 0.025 MATIC
  const amountFormatted = ethers.utils.parseEther(amount.toString());
  const transactionParameters = {
      value: ethers.utils.parseEther(ethToPay.toString())
  };

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const pSaleContract = new ethers.Contract(contractPSaleAddress, contractPSaleABI, signer);
  const skmContract = new ethers.Contract(contractSkmAddress, contractSkmABI, signer);

  skmContract.once("Transfer", (from, to, amount, event) => {
    console.log(from, to, amount);
  });

  //sign the transaction
  try {
      const tx = await pSaleContract.buy(amountFormatted, transactionParameters);
      console.log(tx);

      return {
        isOK: true,
        status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
        txnHash: tx.hash
      };
  } catch (error) {
      return {
        isOK: false,
        status: "😥 " + error.message,
      };
  }
};

export const txnCheck = async (txnHash) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  let txn = await provider.getTransaction(txnHash);
  if (txn) {
      if (txn.blockNumber) {
        return true;
      } else {
        return false;
      }
  }
}

export const txnCheck2 = async (txnHash) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const txn = await provider.getTransaction(txnHash);
  const receipt = await txn.wait()
  return receipt;
}

export const getTotalSupply = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const skmContract = new ethers.Contract(contractSkmAddress, contractSkmABI, provider);
    let totalSupply = await skmContract.totalSupply();

    return ethers.utils.formatEther(totalSupply);
  } catch (error) {
    console.log(error.message);
  }
}

export const mint = async (skmAmount, mintContract) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const skmContract = new ethers.Contract(contractSkmAddress, contractSkmABI, signer);

    const skmAmountFormated = ethers.utils.parseEther(skmAmount.toString());
    let tx = await skmContract.mint(mintContract, skmAmountFormated);

    return {
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>)
    };
  } catch (error) {
    return {
      status: "😥 " + error.message,
    };
  }
}

export const approveSkm = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const skmContract = new ethers.Contract(contractSkmAddress, contractSkmABI, signer);

    const amountApprove = 999999;
    const amountApproveFormated = ethers.utils.parseEther(amountApprove.toString());
    const tx = await skmContract.approve(contractTrackmaniaAddress, amountApproveFormated);

    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const approveSkmMapGame = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const skmContract = new ethers.Contract(contractSkmAddress, contractSkmABI, signer);

    const amountApprove = 999999;
    const amountApproveFormated = ethers.utils.parseEther(amountApprove.toString());
    const tx = await skmContract.approve(contractMapGameAddress, amountApproveFormated);

    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const approveSlp = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const slpContract = new ethers.Contract(contractSlpAddress, contractSlpABI, signer);

    const amountApprove = 999999;
    const amountApproveFormated = ethers.utils.parseEther(amountApprove.toString());
    const tx = await slpContract.approve(contractLpStakingAddress, amountApproveFormated);

    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const getAllowance = async (owner) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const skmContract = new ethers.Contract(contractSkmAddress, contractSkmABI, provider);

    const allowanceAmmount = await skmContract.allowance(owner, contractTrackmaniaAddress);

    return ethers.utils.formatEther(allowanceAmmount);
  } catch (error) {
    console.log(error.message);
  }
}

export const getAllowanceMapGame = async (owner) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const skmContract = new ethers.Contract(contractSkmAddress, contractSkmABI, provider);

    const allowanceAmmount = await skmContract.allowance(owner, contractMapGameAddress);

    return ethers.utils.formatEther(allowanceAmmount);
  } catch (error) {
    console.log(error.message);
  }
}

export const getSlpAllowance = async (owner) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const slpContract = new ethers.Contract(contractSlpAddress, contractSlpABI, provider);

    const allowanceAmmount = await slpContract.allowance(owner, contractLpStakingAddress);

    return ethers.utils.formatEther(allowanceAmmount);
  } catch (error) {
    console.log(error.message);
  }
}

export const getRegisteredRacers = async (owner) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const trackmaniaContract = new ethers.Contract(contractTrackmaniaAddress, contractTrackmaniaABI, provider);

    const racers = await trackmaniaContract.getRacers();

    return racers;
  } catch (error) {
    console.log(error.message);
  }
}

export const register = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const trackmaniaContract = new ethers.Contract(contractTrackmaniaAddress, contractTrackmaniaABI, signer);

    const tx = await trackmaniaContract.register();

    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const distributeRewards = async (racerIDs, ranks) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const trackmaniaContract = new ethers.Contract(contractTrackmaniaAddress, contractTrackmaniaABI, signer);

    const tx = await trackmaniaContract.distributeRewards(racerIDs, ranks);
    
    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const distributeRewardsMapGame = async (address, nbrePoints) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const mapGameContract = new ethers.Contract(contractMapGameAddress, contractMapGameABI, signer);

    const tx = await mapGameContract.distributeRewards(address, nbrePoints);
    
    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const stakeSlp = async (slpAmount) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const lpStakingContract = new ethers.Contract(contractLpStakingAddress, contractLpStakingABI, signer);

    const slpAmountFormated = ethers.utils.parseEther(slpAmount.toString());
    const tx = await lpStakingContract.stakeSLP(slpAmountFormated);

    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const unstakeSlp = async (slpAmount) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const lpStakingContract = new ethers.Contract(contractLpStakingAddress, contractLpStakingABI, signer);

    const slpAmountFormated = ethers.utils.parseEther(slpAmount.toString());
    const tx = await lpStakingContract.unstakeSLP(slpAmountFormated);

    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const claimRewards = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const lpStakingContract = new ethers.Contract(contractLpStakingAddress, contractLpStakingABI, signer);

    const tx = await lpStakingContract.claimRewards();

    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const getStakingReawrds = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const addresses = await provider.listAccounts();
    const address = addresses[0];

    const lpStakingContract = new ethers.Contract(contractLpStakingAddress, contractLpStakingABI, provider);

    const skmRewardsAmmount = await lpStakingContract.getRewards(address);

    return ethers.utils.formatEther(skmRewardsAmmount);
  } catch (error) {
    console.log(error.message);
  }
}

export const getShippingConfig = async (owner) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const mapGameContract = new ethers.Contract(contractMapGameAddress, contractMapGameABI, provider);

    const speeds = await mapGameContract.getSpeeds();
    const costsPerKm = await mapGameContract.getCostsPerKm();

    const shipping = {
      plane: {
        speed: ethers.utils.formatEther(speeds[0]),
        costPerKm: ethers.utils.formatEther(costsPerKm[0])
      },
      car: {
        speed: ethers.utils.formatEther(speeds[1]),
        costPerKm: ethers.utils.formatEther(costsPerKm[1])
      },
      bike: {
        speed: ethers.utils.formatEther(speeds[2]),
        costPerKm: ethers.utils.formatEther(costsPerKm[2])
      }
    };

    return shipping;
  } catch (error) {
    console.log(error.message);
  }
}

export const payShippingFees = async (skmAmount) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const mapGameContract = new ethers.Contract(contractMapGameAddress, contractMapGameABI, signer);

    const tx = await mapGameContract.payShipping(ethers.utils.parseEther(skmAmount.toString()));

    return {
      isOK: true,
      status: (<span>✅{" "}<a target="_blank" href={`${explorerUrl}/tx/${tx.hash}`}>View the status of your transaction on Polygonscan!</a></span>),
      txnHash: tx.hash
    };
  } catch (error) {
    return {
      isOK: false,
      status: "😥 " + error.message,
    };
  }
}

export const testSign = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const message = "Hello World";

    //For sign a test message
    /* const flatSig = await signer.signMessage(message);
    const sig = ethers.utils.splitSignature(flatSig); */

    //For a hash message
    const messageHash = ethers.utils.id(message);
    const messageHashBytes = ethers.utils.arrayify(messageHash);
    const flatSig = await signer.signMessage(messageHashBytes);
    const sig = ethers.utils.splitSignature(flatSig);

    return [flatSig, sig];
  } catch (error) {
    console.log(error.message);
  }
}