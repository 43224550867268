import 'leaflet/dist/leaflet.css';
import './scss/App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Buyer from './views/Buyer';
import Minter from './views/Minter';
import TMSession from './views/TMSession';
import LPStaking from './views/LPStaking';
import MapGame from './views/MapGame';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Buyer/>} />
          <Route path="/mint" element={<Minter/>} />
          <Route path="/trackmania" element={<TMSession/>} />
          <Route path="/lp-staking" element={<LPStaking/>} />
          <Route path="/map-game" element={<MapGame/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
