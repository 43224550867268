import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Header from './../components/Header';
import {
  getTotalSupply,
  mint,
  getSkmBalance
} from "./../util/interact.js";
require('dotenv').config();
const contractPSaleAddress = process.env.REACT_APP_PSALE_CONTRACT_ADDR;
const contractTrackmaniaAddress = process.env.REACT_APP_TRACKMANIA_CONTRACT_ADDR;
const contractLpStakingAddress = process.env.REACT_APP_LPSTAKING_CONTRACT_ADDR;
const contractMapGameAddress = process.env.REACT_APP_MAPGAME_CONTRACT_ADDR;

const Minter = (props) => {

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [balance, setBalance] = useState("");
  const [slpBalance, setSlpBalance] = useState("");
  const [skmBalance, setSkmBalance] = useState("");
  const [skmAmount, setSkmAmount] = useState("");
  const [totalMinted, setTotalMinted] = useState(0);
  const [noLiquidity, setNoLiquidity] = useState(false);
  const [mintContract, setMintContract] = useState(contractPSaleAddress);
  const [contractsBalances, setContractBalances] = useState([]);
  const maxSupply = 1000000;
  const optionsMintContracts = [
    {
      label: 'Presale',
      address: contractPSaleAddress,
      skmBalance: 0
    },
    {
      label: 'Trackmania rewards',
      address: contractTrackmaniaAddress,
      skmBalance: 0
    },
    {
      label: 'LP staking',
      address: contractLpStakingAddress,
      skmBalance: 0
    },
    {
      label: 'Map game',
      address: contractMapGameAddress,
      skmBalance: 0
    }
  ];

  useEffect(async () => {
    getTotalMinted();
    getAllBalances();
  }, []);

  async function getTotalMinted() {
    const totalMinted = await getTotalSupply();
    if(!isNaN(totalMinted)){
      if(totalMinted == maxSupply){
        setNoLiquidity(true);
        setStatus("Warning: max supply reached");
      }
      setTotalMinted(parseInt(totalMinted));
    } else {
      setStatus(totalMinted.status);
    }
  }

  async function onMintPressed() {
    if(!checkLiquidity(skmAmount) || !skmAmount){
      return false;
    }

    callMint(skmAmount, mintContract);
  }

  function checkLiquidity(skmAmount) {
    setSkmAmount(parseInt(skmAmount));
    let total = parseInt(skmAmount)+totalMinted;
    if(total > maxSupply){
      setStatus("Warning: desired amount outrun max supply of 1.000.000 SKM");
      setNoLiquidity(true);
      return false;
    } else {
      setStatus("");
      setNoLiquidity(false);
      return true;
    }
  }

  function changeMintContract(mintContract) {
    setMintContract(mintContract);
  }

  async function callMint(skmAmount, mintContract) {
    const mintedRes = await mint(skmAmount, mintContract);
    setStatus(mintedRes.status);
  }

  async function callSkmBalance(address){
    const skmBalance = await getSkmBalance(address);
    return skmBalance;
  }

  async function getAllBalances(){
    for (const contract of optionsMintContracts) {
      const skmBalance = await callSkmBalance(contract.address);
      contract.skmBalance = skmBalance;
    }
    setContractBalances(optionsMintContracts);
  }


  return (
    <>
      <Header statusCallback={setStatus} balanceCallback={setBalance} slpBalanceCallback={setSlpBalance} skmBalanceCallback={setSkmBalance} walletCallback={setWallet} />
      <div className="view">
        <form>
          <h2>Mint SKM tokens</h2>
          <span className="balance"><strong>Total already minted:</strong> {totalMinted} / {maxSupply} SKM</span>
          <InputGroup size="lg" className="input-mint">
            <FormControl type="number" value={skmAmount} onChange={(event) => checkLiquidity(event.target.value)} min="0" step="1" aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
            <InputGroup.Text id="basic-addon2" className="token-logo"><img className="token-logo-img" src="/assets/images/skm-token.jpg" /> <span>SKM</span></InputGroup.Text>
          </InputGroup>
          <Form.Select value={mintContract} onChange={(event) => changeMintContract(event.target.value)} aria-label="Default select example">
            {optionsMintContracts.map((option) => (
              <option key={option.address} value={option.address}>{option.label}</option>
            ))}
          </Form.Select>
        </form>

        <div className="footer-form">
          <Button variant="info" size="lg" onClick={onMintPressed} disabled={noLiquidity}>
            Mint SKM
          </Button>
        </div>
        <p className="status">{status}</p>

        <div className="contracts-balances">
          {contractsBalances.map((contract) => (
            <div className="contract-balance" key={contract.address}>
              <h3>{contract.label}</h3>
              <p>Balance: <strong>{contract.skmBalance} SKM</strong></p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Minter;
