import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Header from './../components/Header';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import {
    getSlpStakedAmount,
    approveSlp,
    getSlpAllowance,
    stakeSlp,
    unstakeSlp,
    getStakingReawrds,
    claimRewards,
    getSlpTotalSupply,
    txnCheck
} from "./../util/interact.js";

const LPStaking = (props) => {

    //State variables
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [balance, setBalance] = useState("");
    const [slpBalance, setSlpBalance] = useState("");
    const [skmBalance, setSkmBalance] = useState("");
    const [slpStakedAmount, setSlpStakedAmount] = useState("");
    const [slpAmount, setSlpAmount] = useState("");
    const [skmRewardsAmmount, setSkmRewardsAmmount] = useState("");
    const [slpApproved, setSlpApproved] = useState(false);
    const [APR, setAPR] = useState(0);
    const [TVL, setTVL] = useState(0);
    const [pending, setPending] = useState(false);

    useEffect(async () => {
      if (window.ethereum) {
        fetchSlpStakedAmount();
        fetchStakingRewards();
        calculateAPR();

        const allowanceAmount = await getSlpAllowance(walletAddress);
        console.log(allowanceAmount);
        if(allowanceAmount > 0){
            setSlpApproved(true);
        }
      } else {
        alert('You must install Metamask to use this dApp');
      }
    }, [walletAddress]);

    async function fetchSlpStakedAmount(){
        const slpStakedAmount = await getSlpStakedAmount();
        console.log(slpStakedAmount);
        setSlpStakedAmount(slpStakedAmount);
    }

    async function fetchStakingRewards(){
      const skmRewardsAmmount = await getStakingReawrds();
      setSkmRewardsAmmount(skmRewardsAmmount);
    }

    function onMaxPressed(e) {
      e.preventDefault();
      setSlpAmount(slpBalance);
    }

    async function onStakePressed(){
      if(slpAmount <= 0 || !slpAmount){
        setStatus('Input must be > 0');
      } else {
        if(slpAmount <= parseFloat(slpBalance)){
          const tx = await stakeSlp(slpAmount);

          if(tx.isOK){
            setPending(true);
            const myInterval = setInterval(async () => {
                const check = await txnCheck(tx.txnHash);
                if(check){
                    setStatus(tx.status);
                    fetchSlpStakedAmount();
                    fetchStakingRewards();
                    
                    setPending(false);
                    clearInterval(myInterval);
                }
            }, 500);
          } else {
              setStatus(tx.status);
          }

        } else {
          setStatus('Not enough SLP tokens in balance');
        }
      }
    }

    async function onUnstakePressed(){
      if(slpAmount <= 0 || !slpAmount){
        setStatus('Input must be > 0');
      } else {
        if(slpAmount > 0 && slpAmount <= parseFloat(slpStakedAmount)){
          const tx = await unstakeSlp(slpAmount);
          
          if(tx.isOK){
            setPending(true);
            const myInterval = setInterval(async () => {
                const check = await txnCheck(tx.txnHash);
                if(check){
                    setStatus(tx.status);
                    fetchSlpStakedAmount();
                    fetchStakingRewards();
                    
                    setPending(false);
                    clearInterval(myInterval);
                }
            }, 500);
          } else {
              setStatus(tx.status);
          }

        } else {
          setStatus('Not enough SLP tokens staked');
        }
      }
    }

    async function onClaimPressed(){
      const tx = await claimRewards();
      
      if(tx.isOK){
        setPending(true);
        const myInterval = setInterval(async () => {
            const check = await txnCheck(tx.txnHash);
            if(check){
                setStatus(tx.status);
                fetchSlpStakedAmount();
                fetchStakingRewards();
                
                setPending(false);
                clearInterval(myInterval);
            }
        }, 500);
      } else {
          setStatus(tx.status);
      }
    }

    async function onApprove(){
      const res = await approveSlp();
      if(res.isOK){
        setPending(true);
        const myInterval = setInterval(async () => {
            const check = await txnCheck(res.txnHash);
            if(check){
                setSlpApproved(true);
                setStatus(res.status);
                
                setPending(false);
                clearInterval(myInterval);
            }
        }, 500);
      } else {
          setStatus(res.status);
      }
    }

    //TODO - bugged
    async function calculateAPR(){
      //Formula
      //APR = (daily rewards * 365 * token $ value) / (LP total supply * LP $ value)
      const skm$Value = 0.03175; //TODO: get value dynamically
      const lp$Value = 5.25; //TODO: get value dynamically
      const slpTotalSupply = await getSlpTotalSupply(); //Il ne faut pas le total supply ici, mais le nombre de slp lockés dans le contract de staking - il faut donc add une function pour get ça dans le contract lpstaking
      console.log('slpTotalSupply '+slpTotalSupply);
      const TVL = slpTotalSupply*lp$Value;
      const APR = ((600*365*skm$Value)/TVL)*100;
      setAPR(APR);
      setTVL(TVL);
    }


  return (
    <>
      <Header statusCallback={setStatus} balanceCallback={setBalance} slpBalanceCallback={setSlpBalance} skmBalanceCallback={setSkmBalance} walletCallback={setWallet} />
      <div className="view">
        <form>
          <h2>Stake your LP tokens</h2>
          {/* <p><strong>APR: {APR} % - TVL: {TVL} $</strong></p> */}
          <span className="balance"><strong>Balance:</strong> {slpBalance}</span> <Button variant="outline-info" onClick={onMaxPressed}>Max</Button>
          <InputGroup size="lg">
            <FormControl type="number" onChange={(event) => setSlpAmount(event.target.value)} value={slpAmount} min="0" step="1" aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
          </InputGroup>
        </form>
        <Button className="margin-10" variant="info" size="lg" onClick={onApprove} hidden={slpApproved} disabled={pending}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
          Approve SLP
        </Button>
        <Button className="margin-10" variant="info" size="lg" onClick={onStakePressed} hidden={!slpApproved} disabled={pending}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
          Stake
        </Button>
        <Button variant="info" size="lg" onClick={onUnstakePressed} hidden={!slpApproved || slpStakedAmount <= 0} disabled={pending}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
          Unstake
        </Button>
        <p className="status">{status}</p>
        <p className="stake-infos">Staked amount: <strong>{slpStakedAmount}</strong> - Rewards amount: <strong>{skmRewardsAmmount}</strong> SKM</p>
        <Button variant="info" size="lg" onClick={onClaimPressed} disabled={pending}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
          Claim rewards
        </Button>
      </div>
    </>
  );
};

export default LPStaking;
