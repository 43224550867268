import { useEffect, useState } from "react";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Header from './../components/Header';
import { approveSkm, getAllowance, register, distributeRewards, getRegisteredRacers, txnCheck } from "./../util/interact.js";
require('dotenv').config();
const adminAddress = process.env.REACT_APP_ADMIN_WALLET;
const adminTmAddress = process.env.REACT_APP_ADMIN_TM_WALLET;
const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL;
const REACT_APP_NODE_URL = process.env.REACT_APP_NODE_URL;

const TMSession = (props) => {

    //State variables
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [balance, setBalance] = useState("");
    const [slpBalance, setSlpBalance] = useState("");
    const [skmBalance, setSkmBalance] = useState("");
    const [isConnected, setIsConnected] = useState(false);
    const [racers, setRacers] = useState([]);
    const [wsClient, setWSClient] = useState({});
    const [skmApproved, setSkmApproved] = useState(false);
    const [racesArchives, setracesArchives] = useState([]);
    const [sessionId, setSessionId] = useState(0);
    let raceRanking = [];
    const pointsArr = [10, 6, 4, 2];
    const [allTimePointsRankArr, setAllTimePointsRankArr] = useState([]);
    const [pending, setPending] = useState(false);


    useEffect(async () => {
        setWSListenners(wsClient, walletAddress);

        const allowanceAmount = await getAllowance(walletAddress);
        //console.log(allowanceAmount);
        if(allowanceAmount > 0){
            setSkmApproved(true);
        }

        await calculateAllTimeRanking();

        //console.log(racers);
        //console.log(isConnected);
    }, [racers, isConnected, wsClient, walletAddress]);

    async function connectWs() {
        //Interact with contract to pay entry fees
        //Display rewards amount next to rank
        //Step 1: make approve on SKM token in order to authorize Trackmania contract to spend SKM of user
        //Step 2: interact with Trackmania contract to pay regiration fees 
        let needToPayFees = true;

        //If user is already registered in contract, no nood to repay fees
        const registeredRacers = await getRegisteredRacers();
        console.log(registeredRacers.length);
        if(registeredRacers.length > 0){
            registeredRacers.forEach(racer => {
                console.log(racer.userID);
                if(racer.userID == walletAddress){
                    needToPayFees = false;
                    setWSClient(new W3CWebSocket(REACT_APP_WS_URL+'?id='+walletAddress));
                    return;
                }
            });
        }

        //Else, let's go pay fees
        if(needToPayFees){
            const isRegistered = await register();

            if(isRegistered.isOK){
                setPending(true);
                const myInterval = setInterval(async () => {
                    const check = await txnCheck(isRegistered.txnHash);
                    if(check){
                        setWSClient(new W3CWebSocket(REACT_APP_WS_URL+'?id='+walletAddress));
                        setStatus(isRegistered.status);
                        
                        setPending(false);
                        clearInterval(myInterval);
                    }
                }, 500);
            } else {
                setStatus(isRegistered.status);
            }
        }

        //Init raceArchives
        const racesArchivesFile = await getRacesArchivesFile();
        if(racesArchivesFile.length > 0){
            setracesArchives(racesArchivesFile);
            setSessionId(racesArchivesFile.length);
        }
    }

    function setWSListenners(client) {
        client.onopen = () => {
            console.log('WebSocket Client Connected');
            setIsConnected(true);
        };

        client.onmessage = (message) => {
            if(message.data == 'closeConnection'){
                wsClient.close();
                setIsConnected(false);
            } else {
                const racersServer = JSON.parse(message.data);
                setRacers(racersServer);
            }
        };
    }

    function closeWs() {
        wsClient.send(JSON.stringify({
            userID: walletAddress,
            type: 'close'
        }));
        wsClient.close();
        setIsConnected(false);
    }

    function closeWsForAll() {
        wsClient.send(JSON.stringify({
            type: 'closeAll'
        }));
        wsClient.close();
        setIsConnected(false);
    }

    function broadcastRanking(userID, rank, points, username) {
        racers.forEach(racer => {
            if(racer.userID == userID){
                racer.rank = rank;
                racer.points = points;
                racer.username = username;
            }
        });

        console.log('-----racers send to ws');
        console.log(racers);

        wsClient.send(JSON.stringify({
            users: racers,
            type: 'ranking'
        }));
    }

    async function onApprove(){
        const res = await approveSkm();

        if(res.isOK){
            setPending(true);
            const myInterval = setInterval(async () => {
                const check = await txnCheck(res.txnHash);
                if(check){
                    setSkmApproved(true);
                    setStatus(res.status);
                    
                    setPending(false);
                    clearInterval(myInterval);
                }
            }, 500);
        } else {
            setStatus(res.status);
        }
    }

    async function validateRanking() {
        let racerIDs = [];
        let ranks = [];
        racers.forEach(racer => {
            racerIDs.push(racer.userID);
            ranks.push(racer.rank);
        });

        const res = await distributeRewards(racerIDs, ranks);

        if(res.isOK){
            setPending(true);
            const myInterval = setInterval(async () => {
                const check = await txnCheck(res.txnHash);
                if(check){
                    closeWsForAll();
                    setStatus(res.status);
                    
                    setPending(false);
                    clearInterval(myInterval);
                }
            }, 500);
        } else {
            setStatus(res.status);
        }

        //Add raceArchives to db file
        const writeFile = await setRacesArchivesFile(JSON.stringify(racesArchives));
        console.log('setRacesArchivesFile');
        console.log(writeFile);

        //Reinit display
        const inputs = document.querySelectorAll(".session-wrapper input[type=number]");
        inputs.forEach(input => {
            input.value = 0;
        });
        const itemsPoints = document.querySelectorAll(".session-wrapper td strong");
        itemsPoints.forEach(item => {
            item.innerHTML = 0;
        });
    }

    function addRaceToArchives() {
        console.log('sessionId '+sessionId);
        if(racesArchives[sessionId]){
            console.log('session exists');
            racesArchives[sessionId].push(raceRanking);
        } else {
            console.log('session not exists');
            racesArchives[sessionId] = [raceRanking];
        }
        console.log('-----racesArchives');
        console.log(racesArchives);

        raceRanking = [];
        const inputs = document.querySelectorAll(".race-wrapper input[type=number]");
        inputs.forEach(input => {
            input.value = 0;
        });
        
        //Calculate global ranking
        let pointsRankArr = [];
        racers.forEach(racer => {
            let pointsRank = 0;
            let points = 0;
            let username = '';
            racesArchives[sessionId].forEach(racersArchives => {
                racersArchives.forEach(racerArchive => {
                    if(racer.userID == racerArchive.userID){
                        const rank = racerArchive.rank;
                        pointsRank += parseInt(rank); 
                        points += pointsArr[rank-1];
                        username = racerArchive.username;
                    }
                });
            });

            const racerPoints = {
                userID: racer.userID,
                points: points,
                username: username
            };
            pointsRankArr.push(racerPoints);
        });

        pointsRankArr.sort((a, b) => b.points - a.points);

        console.log('-----pointsRankArr');
        console.log(pointsRankArr);

        //Update racers arr rankings with calculated ranking
        console.log(racers);
        racers.forEach(racer => {
            pointsRankArr.forEach(pointsRankArrForEach);

            function pointsRankArrForEach(element, index, array){
                if(racer.userID == element.userID){
                    racer.rank = index+1;
                    racer.points = element.points;
                    racer.username = element.username;

                    if(!racer.username){
                        const userLines = document.querySelectorAll(".race-wrapper .last-racers");
                        userLines.forEach(userLine => {
                            const userId = userLine.querySelectorAll(".user-id")[0];
                            const username = userLine.querySelectorAll(".username input")[0];
                            if(userId.innerText == racer.userID){
                                racer.username = username.value;
                            }
                        });
                    }

                    //Update global ranking input values for all ws clients
                    broadcastRanking(racer.userID, racer.rank, racer.points, racer.username);
                }
            }
        });

        racers.sort((a, b) => b.points - a.points);

        console.log('-----racers');
        console.log(racers);
        setRacers(racers);
    }

    async function calculateAllTimeRanking(){
        setAllTimePointsRankArr([]);
        const allTimePointsRankArrTmp = [];
        const races = await getRacesArchivesFile();
        let nbreRaces = 0;
        const globalRacers = [];

        races.forEach(session => {
            session.forEach(racersArchives => {
                racersArchives.forEach(racerArchive => {
                    if(!globalRacers.includes(racerArchive.userID)){
                        globalRacers.push(racerArchive.userID);
                    }
                });
                nbreRaces++;
            });
        });

        console.log(globalRacers);

        globalRacers.forEach(racerUid => {
            let pointsRank = 0;
            let points = 0;
            let username = '';

            races.forEach(session => {
                session.forEach(racersArchives => {
                    racersArchives.forEach(racerArchive => {
                        if(racerUid == racerArchive.userID){
                            const rank = racerArchive.rank;
                            pointsRank += parseInt(rank); 
                            points += racerArchive.points;
                            username = racerArchive.username;
                        }
                    });
                });
            });

            const racerPoints = {
                userID: racerUid,
                points: points,
                averageRank: pointsRank/nbreRaces,
                username: username
            };
            allTimePointsRankArrTmp.push(racerPoints);
        });

        allTimePointsRankArrTmp.sort((a, b) => b.points - a.points);

        console.log('nbreRaces');
        console.log(nbreRaces);
        console.log('allTimePointsRankArrTmp');
        console.log(allTimePointsRankArrTmp);
        
        setAllTimePointsRankArr(allTimePointsRankArrTmp);
    }

    function onAddRaceRanking(userID, rank) {
        const racer = {
            userID: userID,
            rank: rank,
            points: pointsArr[rank-1]
        };

        let find = false;
        raceRanking.forEach(racer => {
            if(racer.userID == userID){
                racer.rank = rank;
                racer.points = pointsArr[rank-1];
                find = true;
            }

            const userLines = document.querySelectorAll(".race-wrapper .last-racers");
            userLines.forEach(userLine => {
                const userId = userLine.querySelectorAll(".user-id")[0];
                const username = userLine.querySelectorAll(".username input")[0];
                if(userId.innerText == racer.userID){
                    racer.username = username.value;
                }
            });
        });

        console.log(racer);

        if(!find){
            raceRanking.push(racer);
        }

        console.log('------raceRanking');
        console.log(raceRanking);
    }

    async function getRacesArchivesFile(){
        const res = await axios.get(REACT_APP_NODE_URL+'/races-archives');
        return res.data;
    }

    async function setRacesArchivesFile(data){
        const post = {
            racesArchives: data
        };
         
        const res = await axios.post(REACT_APP_NODE_URL+'/write-races-archives', post);
        return res.data;
    }


    return (
        <>
            <Header statusCallback={setStatus} balanceCallback={setBalance} slpBalanceCallback={setSlpBalance} skmBalanceCallback={setSkmBalance} walletCallback={setWallet} />
            <div className="view">
                <h2>Play Trackmania and get rewards</h2>
                <div className="session-wrapper" hidden={!walletAddress}>
                    <div className="no-session" hidden={isConnected}>
                        <p>Not connected to session yet.</p>
                        <p>A session costs <strong>8 SKM</strong></p>
                        <Button variant="info" size="lg" onClick={connectWs} disabled={isConnected || pending} hidden={!skmApproved}>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
                            Connect to session
                        </Button>
                        <Button variant="info" size="lg" onClick={onApprove} hidden={skmApproved} disabled={pending}>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
                            Approve SKM
                        </Button>
                    </div>

                    <div className="active-session" hidden={!isConnected}>
                        <p>Global session ranking</p>
                        <p>Rewards amounts: <strong>1st</strong> 80 SKM - <strong>2nd</strong> 50 SKM - <strong>3rd</strong> 20 SKM - <strong>4th</strong> 10 SKM</p>
                        <table>
                            <tbody>
                                <tr>
                                    <th className="rank-col">Rank</th>
                                    <th>User wallet address</th>
                                    <th>Username</th>
                                    <th>Points</th>
                                </tr>
                                {racers.map((racer) => (
                                    <tr key={racer.userID}>
                                        <td className="rank-col">
                                            <InputGroup>
                                                <FormControl data-uid={racer.userID} type="number" min="1" max={racers.length} disabled={true} onChange={(event) => broadcastRanking(racer.userID, event.target.value, racer.points, racer.username)} value={racer.rank} aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
                                            </InputGroup>
                                        </td>
                                        <td>{String(racer.userID).substring(0, 6)+"..." +String(racer.userID).substring(38)}</td>
                                        <td>{racer.username}</td>
                                        <td><strong>{racer.points}</strong></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Button className="margin-10" variant="info" size="lg" onClick={validateRanking} hidden={(adminAddress != walletAddress && adminTmAddress != walletAddress) || !skmApproved} disabled={pending}>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
                            Validate ranking and get rewards
                        </Button>
                        {/* <Button className="margin-10" variant="outline-info" onClick={closeWs} disabled={!isConnected}>Stop session</Button> */}
                        <Button variant="info" size="lg" onClick={onApprove} hidden={(adminAddress != walletAddress && adminTmAddress != walletAddress) || skmApproved} disabled={pending}>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
                            Approve SKM
                        </Button>
                    </div>

                    <p className="status">{status}</p>
                </div>

                <div className="race-wrapper" hidden={(adminAddress != walletAddress && adminTmAddress != walletAddress) || !isConnected}>
                    <p>Last race ranking</p>
                    <table>
                        <tbody>
                            <tr>
                                <th className="rank-col">Rank</th>
                                <th>User wallet address</th>
                                <th>Username</th>
                            </tr>
                            {racers.map((racer) => (
                                <tr className="last-racers" key={racer.userID}>
                                    <td className="rank-col">
                                        <InputGroup>
                                            <FormControl type="number" min="1" max={racers.length} onChange={(event) => onAddRaceRanking(racer.userID, event.target.value)} aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
                                        </InputGroup>
                                    </td>
                                    <td className="user-id">{racer.userID}</td>
                                    <td className="username">
                                        <InputGroup>
                                            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
                                        </InputGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Button variant="outline-info" size="lg" onClick={addRaceToArchives}>Add race ranking</Button>
                </div>

            </div>

            <div className="all-time-ranking">
                <h2>All time ranking</h2>
                <table>
                    <tbody>
                        <tr>
                            <th>Rank</th>
                            <th>User wallet address</th>
                            <th>Username</th>
                            <th>Points</th>
                        </tr>
                        {allTimePointsRankArr.map((globalRacer) => (
                            <tr key={globalRacer.userID}>
                                <td>{allTimePointsRankArr.indexOf(globalRacer)+1}</td>
                                <td>{String(globalRacer.userID).substring(0, 6)+"..." +String(globalRacer.userID).substring(38)}</td>
                                <td><strong>{globalRacer.username}</strong></td>
                                <td><strong>{globalRacer.points}</strong></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TMSession;
