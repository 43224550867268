import { useState } from "react";
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import Header from './../components/Header';
import {
  getEthBalance,
  buySkm,
  txnCheck
} from "./../util/interact.js";

const Buyer = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [balance, setBalance] = useState("");
  const [slpBalance, setSlpBalance] = useState("");
  const [skmBalance, setSkmBalance] = useState("");
  const [ethAmount, setEthAmount] = useState("");
  const [skmAmount, setSkmAmount] = useState("");
  const [noLiquidity, setnoLiquidity] = useState(false);
  const [pending, setPending] = useState(false);


  async function fetchBalance(address) {
    const balance = await getEthBalance(address);
    setBalance(balance);
  }

  async function onBuyPressed() {
    if(ethAmount > 0 && ethAmount <= balance && skmAmount > 0){
      console.log('MATIC '+ethAmount);
      console.log('SKM '+skmAmount);
    }

    const txResponse = await buySkm(walletAddress, skmAmount);

    if(txResponse.isOK){
      setPending(true);
      const myInterval = setInterval(async () => {
        const check = await txnCheck(txResponse.txnHash);
        if(check){
          setStatus(txResponse.status);
          fetchBalance(walletAddress);

          setPending(false);
          clearInterval(myInterval);
        }
      }, 500);
    } else {
      setStatus(txResponse.status);
    }
  }

  function onMaxPressed(e) {
    e.preventDefault();
    setEthAmount(balance);
    convert('ETH', balance);
  }

  function convert(from, amount) {
    if(from == 'ETH'){
      setEthAmount(amount);
      let skmAmountInputed = amount*40;
      setSkmAmount(skmAmountInputed);
      checkLiquidity(amount);
    } else {
      setSkmAmount(amount);
      let ethAmountInputed = amount*0.025;
      setEthAmount(ethAmountInputed);
      checkLiquidity(ethAmountInputed);
    }
  }

  function checkLiquidity(ethAmount){
    if(parseFloat(ethAmount) > balance){
      setnoLiquidity(true);
      setStatus("Insufficient liquidity");
      console.log(noLiquidity);
    } else {
      setnoLiquidity(false);
      setStatus('');
      console.log(noLiquidity);
    }
  }


  return (
    <>
      <Header statusCallback={setStatus} balanceCallback={setBalance} slpBalanceCallback={setSlpBalance} skmBalanceCallback={setSkmBalance} walletCallback={setWallet} />

      <div className="view">
        <form>
          <h2><img className="token-logo-img" src="/assets/images/matic-token.png" /> MATIC</h2>
          <span className="balance"><strong>Balance:</strong> {balance}</span> <Button variant="outline-info" onClick={onMaxPressed}>Max</Button>
          <InputGroup size="lg">
            <FormControl type="number" onChange={(event) => convert('ETH', event.target.value)} value={ethAmount} min="0" step="0.001" aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
            <InputGroup.Text id="basic-addon2" className="token-logo"><img className="token-logo-img" src="/assets/images/matic-token.png" /> <span>MATIC</span></InputGroup.Text>
          </InputGroup>
          <h2><img className="token-logo-img" src="/assets/images/skm-token.jpg" /> SKM</h2>
          <InputGroup size="lg">
            <FormControl type="number" onChange={(event) => convert('SKM', event.target.value)} value={skmAmount} min="0" step="1" aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
            <InputGroup.Text id="basic-addon2" className="token-logo"><img className="token-logo-img" src="/assets/images/skm-token.jpg" /> <span>SKM</span></InputGroup.Text>
          </InputGroup>
        </form>

        <div className="footer-form">
          <Button variant="info" size="lg" onClick={onBuyPressed} disabled={noLiquidity || pending}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!pending} />
            Buy SKM
          </Button>
        </div>

        <p className="status">{status}</p>
        
      </div>
    </>
  );
};

export default Buyer;
