import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import {
  connectWallet,
  getCurrentWalletConnected,
  getEthBalance,
  getSlpBalance,
  getSkmBalance
} from "./../util/interact.js";

require('dotenv').config();
const contractSkmABI = require("../contracts-abi/Skumy.sol/Skumy.json");
const contractSkmAddress = process.env.REACT_APP_SKUMY_TOKEN_ADDR;
const contractSlpABI = require("../contracts-abi/SLPTest.sol/SLPTest.json");
const contractSlpAddress = process.env.REACT_APP_SLP_CONTRACT_ADDR;

const ethers = require('ethers');
const correctChainId = process.env.REACT_APP_CHAIN_ID;

const Wallet = (props) => {

    const [walletAddress, setWallet] = useState("");

    useEffect(async () => {
        fetchWallet();
        addWalletListener();
    }, []);

    function addContractsListeners(address){
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const skmContract = new ethers.Contract(contractSkmAddress, contractSkmABI, provider);
        const slpContract = new ethers.Contract(contractSlpAddress, contractSlpABI, provider);

        skmContract.on("Transfer", (from, to, amount) => {
            //console.log(from, to, amount, address);
            if(to == address || from == address){
                fetchBalance(address);
                fetchSkmBalance(address);
            }
        });

        slpContract.on("Transfer", (from, to, amount) => {
            //console.log(from, to, amount);
            if(to == address || from == address){
                fetchBalance(address);
                fetchSlpBalance(address);
            }
        });
    }

    async function fetchWallet() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const { chainId } = await provider.getNetwork();
            console.log(chainId);
            if(chainId == correctChainId){
                await window.ethereum.enable();
                const {address, status} = await getCurrentWalletConnected();
                props.statusCallback(status);
                setWallet(address);
                props.walletCallback(address);

                if(address){
                    fetchBalance(address);
                    fetchSlpBalance(address);
                    fetchSkmBalance(address);
                    addContractsListeners(address);
                }
            } else {
                alert('You must connect your Metamask to Polygon network');
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${Number(correctChainId).toString(16)}` }],
                });
            }
        } else {
            alert('You must install Metamask to use this dApp');
        }
    }

    async function fetchBalance(address) {
        const balance = await getEthBalance(address);
        props.balanceCallback(balance);
    }

    async function fetchSlpBalance(address){
        const slpBalance = await getSlpBalance(address);
        props.slpBalanceCallback(slpBalance);
    }

    async function fetchSkmBalance(address){
        const skmBalance = await getSkmBalance(address);
        props.skmBalanceCallback(skmBalance);
    }
    
    const connectWalletPressed = async () => {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const { chainId } = await provider.getNetwork();
            console.log(chainId);
            if(chainId == correctChainId){
                const walletResponse = await connectWallet();
                props.statusCallback(walletResponse.status);
                setWallet(walletResponse.address);
                props.walletCallback(walletResponse.address);
            } else {
                alert('You must connect your Metamask to Polygon network');
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${Number(correctChainId).toString(16)}` }],
                });
            }
        } else {
            alert('You must install Metamask to use this dApp');
        }
    };
    
    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                console.log('account changed');
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    props.walletCallback(accounts[0]);
                    props.statusCallback("");
                    fetchWallet();
                } else {
                    setWallet("");
                    props.walletCallback("");
                    props.balanceCallback(0);
                    props.statusCallback("🦊 Connect to Metamask using the top right button.");
                }
            });

            window.ethereum.on('chainChanged', function(chainId){
                fetchWallet();
            });
        } else {
            props.statusCallback(
            <p>
                {" "}
                🦊{" "}
                <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
                </a>
            </p>
            );
        }
    }


    return (
        <Button className="wallet" variant="outline-info" onClick={connectWalletPressed}>
            {walletAddress.length > 0 ? (
                "Connected: " +
                String(walletAddress).substring(0, 6) +
                "..." +
                String(walletAddress).substring(38)
            ) : (
                <span>Connect Wallet</span>
            )}
        </Button>
    );
};

export default Wallet;
